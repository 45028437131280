<template>
  <nav :class="['menu', classComputed]">
    <div
      v-for="(item, key) in data"
      :key
      :class="['menu__item', item?.submenu?.length ? 'menu__item--drop' : '']"
      @touchstart="item?.submenu?.length ? menuItemClick($event) : undefined"
    >
      <LocaleLink
        :to="item.type === 'page' ? `/pages/${item.page}` : item.url"
        class="menu__link"
      >
        {{ item.title }}

        <Icon
          v-if="item?.submenu?.length"
          name="arrow-down"
          class="menu__arrow"
        />
      </LocaleLink>

      <div
        v-if="item?.submenu?.length"
        class="menu__drop"
      >
        <LocaleLink
          v-for="(subItem, subIdx) in item?.submenu"
          :key="subIdx"
          data-drop-link="true"
          :to="subItem.type === 'page' ? `/pages/${subItem.page}` : subItem.url"
          class="menu__link"
        >
          {{ subItem.title }}
        </LocaleLink>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';
import { LocaleLink } from '@shared/LocaleLink';

interface IProps {
  data: any[];
  variant?: string;
  color?: string;
}

const props = defineProps<IProps>();

const classComputed = computed(() => {
  return [props.variant ? `menu--${props.variant}` : '', props.color ? `menu--${props.color}` : ''];
});

const menuItemClick = ($event: any) => {
  const currentTarget = $event.currentTarget as HTMLInputElement;
  const target = $event.target as HTMLInputElement;

  if (target.dataset.dropLink) {
    return;
  }

  $event.preventDefault();
  currentTarget.classList.toggle('is-active');
};
</script>

<style lang="scss" src="./Menu.scss" />
